
































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class BasicHelp extends Vue {
  tab = null;
}
